import "src/components/PCPageMgr";import "src/components/Layout";import "src/components/View";import "src/components/ButtonExt";import "src/component/script/ClickGoUrl";import "src/component/script/CallbackScript";import "src/component/script/ClickScript";import "src/components/Text";import "src/components/JList";import "src/components/Image";import "src/component/script/BaseProps";import "src/component/script/GGEdge";import "src/component/script/AggregationScript";import "src/component/script/CheckScript";import "src/component/script/HandleScript";import "src/component/source/SourceStore";import "src/component/jj/JImage";import "src/component/antd/AntPagination";import "src/component/source/SourceArtical";import "src/component/source/SourceNet";import "src/component/script/TimeScript";import "src/components/HtmlTrans";import "src/component/script/ValueScript";import "src/component/script/GetSourceScript";import "src/component/script/MapScript";import "src/components/HtmlRicher";import "src/component/script/ExtStyleScript";import "src/components/SwiperJs";import "src/components/Button";import "src/components/Pagination";import "src/component/script/ArrayHandleScript";import "src/component/script/StringScript";import "src/component/script/AddStyleTab";import "src/component/script/ClickGoSource";import "src/components/PCForm";import "src/component/antd/AntInput";import "src/component/antd/AntRadio";import "src/component/antd/AntUpload";import "src/components/Modal";import "src/component/script/ToastScript";import "src/component/script/ImmediateScript";import "src/components/ImportPage";
        const UILoadingManifest = (key: string) => {
            switch (key) {
                
                default:
                    return null;
            }
        };
        
        UILoadingManifest.PageManifest ={
            home:["PCPage","View","ImportPage","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","Text","JList","Image","BaseProps","GGEdge","AggregationScript","CheckScript","HandleScript","ImmediateScript","SourceStore"],index:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","JList","Text","BaseProps","AggregationScript","GGEdge","JImage","AntPagination","SourceArtical","SourceNet"],gameInfo:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","BaseProps","ClickGoUrl","TimeScript","HtmlTrans","GGEdge","SourceNet","ValueScript","GetSourceScript"],aboutus:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","Image","Text","BaseProps","MapScript","GGEdge","HtmlRicher","ExtStyleScript","CheckScript","SwiperJs","Button","JList","ArrayHandleScript","SourceStore"],gongyi:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","JList","Image","AggregationScript","BaseProps","GGEdge","Pagination","HandleScript","ClickGoUrl","SourceArtical"],detail:["PCPage","View","ButtonExt","ClickScript","CallbackScript","Text","BaseProps","ClickGoUrl","TimeScript","HtmlRicher","GGEdge","SourceNet","GetSourceScript"],apply:["PCPage","View","ClickScript","CallbackScript","ButtonExt","ClickGoUrl","ClickGoSource","AggregationScript","HandleScript","ExtStyleScript","CheckScript","BaseProps","GGEdge","Text","JList","StringScript","AddStyleTab","ArrayHandleScript","HtmlTrans","Image","JImage","SourceArtical","SourceStore","SourceNet"],report:["PCPage","SourceNet","View","Text","ButtonExt","ClickGoUrl","CallbackScript","PCForm","AntInput","AntRadio","AntUpload","Modal","BaseProps","ToastScript","CheckScript","ClickGoSource","ValueScript","GGEdge","ClickScript"],comics:["PCPage","View","ButtonExt","ClickGoUrl","CallbackScript","ClickScript","JList","Text","BaseProps","AggregationScript","GGEdge","JImage","AntPagination","SourceArtical"],PageMgrUIS:["PCPage","SourceStore"],
        }

        export default UILoadingManifest;